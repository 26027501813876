.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-close {
  position: absolute;
  top: -5px;
  right: 10px;
  cursor: pointer;
}
.modal .content {
  position: relative;
  width: 75%;
  background: #000000;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 5rem;
}
.modal-left {
  width: 50%;
}
.modal-left img {
  width: 100%;
  /* height: 280px; */
}
.modal-right {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
}
.modal-right .heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  padding-left: 10px;
}
.modal-right .sub-heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  padding-left: 10px;
}
.modal-right .list {
  list-style-type: upper-roman;
  list-style-position: inside;
  margin: 0;
  padding-left: 10px;
}
.modal-right .list li {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.modal-generate {
  cursor: pointer;
  overflow: hidden;
  width: 232px;
  height: 50px;
  background: radial-gradient(50% 50% at 50% 50%, #fdc850 0%, #ea5521 100%);
  border-radius: 10px;
}
.modal-generate span {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

.generated-link {
  overflow: hidden;
  width: 341px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #0961a6;
  border-radius: 10px;
}
.generated-link span {
  text-overflow: ellipsis;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  padding: 0 10px;
  line-height: 50px;
  text-align: center;
  color: #A7A7A7;
  width: 98%; 
  white-space: nowrap;
}
.modal-btn {
  display: flex;
  gap: 10px;
}
.modal-mob-heading {
  display: none;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  padding-left: 10px;
}
@media (max-width: 1000px) {
  .modal .content {
    width: 85%;
    padding: 4rem 3rem;
  }
  /* .modal-left img {
    width: 300px;
  } */
}
@media (max-width: 786px) {
  .modal .content {
    border-radius: 20px;
    padding: 20px;
    flex-direction: column;
    width: 70%;
  }
  .modal-left {
    width: 100%;
    text-align: center;
  }
  .modal-left img {
    height: 220px;
  }
  .modal-right {
    width: 90%;
    margin: 0 auto;
  }
  .modal-mob-heading {
    display: block;
    font-size: 24px;
    line-height: 36px;
  }
  .modal-right .heading {
    display: none;
  }
  .modal-right .sub-heading {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }

  .modal-right .list li {
    font-family: "Manrope";
    font-style: normal;
  }
  .modal-generate {
    width: 232px;
  }
  .generated-link {
    width: 232px;
  }
  .modal-btn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}

@media (max-width: 450px) {
  .modal .content {
    width: 90%;
    padding: 10px;
  }
  .modal-generate {
    width: 100%;
  }
  .modal-left img {
    height: 140px;
  }
  .generated-link {
    width: 100%;
  }
}
