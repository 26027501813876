.minting-content .camel {
  /* height: 250px; */
  width: 300px;
}
.mob-link {
  padding: 0px 15px;
  height: 40px;
  border-radius: 7px;
  text-align: center;
  color: #fff;
  line-height: 37px;
  font-size: 18px;
  font-weight: 500;
  background-color: #f5841f;
  border: 1px solid #f5841f;
  margin-top: 10px;
}
.mob-link a {
  text-decoration: none;
  color: #fff;
}
.minting-content .connect {
  background: radial-gradient(50% 50% at 50% 50%, #fdc850 0%, #ea5521 100%);
  border-radius: 20px;
  width: 250px;
  height: 55px;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.minting-content .connect span {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
}
.minting-content .refer {
  cursor: pointer;
  width: 250px;
  height: 55px;
  border: none;
  background: radial-gradient(50% 50% at 50% 50%, #5095fd 0%, #005b9c 100%);
  border-radius: 20px;
}
.minting-content .refer span {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  text-transform: capitalize;
}
.my-web3-button {
  position: relative;
}

w3m-core-button w3m-connect-button w3m-button-big button {
  background-color: #f00 !important; /* Replace with your desired background color */
  /* Add any other styles as needed */
}
@media (max-width: 1000px) {
  .minting-content .camel {
    /* height: 200px; */
    width: 280px;
  }
  .minting-content .connect {
    width: 200px;
  }
  .minting-content .connect span {
    font-size: 20px;
    line-height: 25px;
  }
  .minting-content .refer {
    width: 200px;
  }
  .minting-content .refer span {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 786px) {
  .minting-content .camel {
    /* height: 200px; */
    width: 280px;
  }
  .minting-content .connect {
    border-radius: 10px;
    height: 45px;
  }
  .minting-content .refer {
    border-radius: 10px;
    height: 45px;
  }
}

@media (max-width: 400px) {
  .minting-content .camel {
    /* height: 200px; */
    width: 280px;
  }
  .minting-content .connect span {
    font-size: 18px;
    line-height: 36px;
  }
  .minting-content .refer span {
    font-size: 18px;
    line-height: 36px;
  }
}
